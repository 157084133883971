html, body {
    min-height: 100vh;
    padding: 0;
    margin: 0;
}

body {
    background: linear-gradient(145deg, hsl(220, 91%, 90%), white);
    display: flex;
    flex-flow: column;
}

#root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
}
